<template>
  <!-- 学科专题 -->
  <div class="disciplineListrt" v-show='showFlag'>
    <div class="authorsearchBox">
      <el-input class="searchBox" maxlength='20' @keyup.enter.native="handleauthorBtn" v-model="disciplinesearchs"
        placeholder="输入专题名称、主题词搜索" clearable></el-input>
      <el-button type="primary" class="searchlistBtn" @click="handleauthorBtn">结果中搜索</el-button>
    </div>
    <div style="margin-bottom: 15px;">
      <span>为您查到</span>
      <span class="searchNum">{{ total }}</span>
      <span>条数据</span>
    </div>
    <template v-if='total > 0'>
      <div class="ListgroupBox">
        <div class="Listgroup" @click="handlego(item.id)" v-for="(item, index) in disciplineList" :key="index">
          <div class="itemimgbox">
            <div class="itemdiscipline" v-if="item.count">主文献量：{{ item.count }}</div>
            <img :src="item.cover" v-if="item.cover" class="imgdis" alt />
            <img src="../../assets/images/home/xuekeimg.png" class="imgdis" v-else alt />
          </div>
          <div class="itemtexBox">
            <p class="itemtitle" :title="item.name">{{ item.name }}</p>
            <p class="word">主题词：{{ item.theme_word }}</p>
            <div class="itemtxt">
              <span :title="item02" class="spantxt" v-for="(item02, index) in item.disciplineList" :key="index">
                {{ item02
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="pageBox">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page" :page-sizes="[9, 18, 27, 36]" :page-size="pageRow"
          layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
    </template>

    <template v-else>
      <div class='noData'>
        <img src="../../assets/images/home/nodatasmall.png" alt="">
        <span>暂无数据</span>
      </div>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  data () {
    return {
      showFlag: false,
      currentPage: 1,
      page: 1, // 页码
      pageRow: 9, // 每页显示的条目数
      total: 0,
      // institutionselecttree: [],
      disciplineList: [],
      disciplinesearch: '',
      disciplinesearchs: '',
      firstLevelList: [],
      secondLevelList: []
    }
  },
  mounted () {
    this.getdisciplinelist()
  },
  methods: {
    handleSizeChange (val) {
      this.page = 1
      this.pageRow = val
      this.getdisciplinelist()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getdisciplinelist()
    },
    // 点击跳转详情
    handlego (id) {
      const routeData = this.$router.resolve({
        path: '/disciplinedetail?id=' + id
        // query: params
      })
      window.open(routeData.href, '_blank')
      // this.$router.push('/disciplinedetail?id=' + id)
    },
    async getdisciplinelist () {
      const { data: res } = await this.$http({
        url: '/topicList/getTopicList',
        method: 'post',
        data: {
          page: this.page,
          pageRow: this.pageRow,
          content: this.disciplinesearch,
          firstLevelList: this.firstLevelList,
          secondLevelList: this.secondLevelList
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.showFlag = true
      this.$emit('discip', res.data)
      this.total = res.data.total
      this.disciplineList = res.data.records
    },
    handleauthorBtn () {
      this.disciplinesearch = this.disciplinesearchs
      // this.disciplinesearch = this.disciplinesearch.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.page = 1
      this.$emit('disciplieSearchInfo', this.disciplinesearch)
      this.getdisciplinelist()
    }
  },
  watch: {
    '$store.state.seconddiscipline': {
      deep: true,
      handler (data, oldData) {
        const path = this.$route.fullPath
        let sub = ''
        if (path.split('?').length > 1) {
          sub = path.split('?')[1].split('=')[0]
        }
        const _path = path.split('?')[0]
        this.page = 1
        if (
          _path === '/disciplineList' &&
          JSON.stringify(data) !== JSON.stringify(oldData) &&
          sub !== 'subjectName'
        ) {
          this.secondLevelList = data
          this.getdisciplinelist()
          return
        }
        if (
          JSON.stringify(data) !== JSON.stringify(oldData) &&
          oldData !== undefined
        ) {
          this.secondLevelList = data
          this.getdisciplinelist()
          // console.log('mainlist', data)
        }
        // this.secondLevelList = data;
      }
    }
    // "$store.state.firstdiscipline": {
    //   deep: true,
    //   handler(data) {
    //     this.firstLevelList = data;
    //     this.page = 1;
    //     this.getdisciplinelist();
    //       console.log("a5555");
    //   }
    // }
    // "$store.state.firstselectedTree": {
    //   deep: true,
    //   handler(data) {
    //     this.firstselectedTree = data;
    //   }
    // },
    // 监听选择学科领域树
    // '$store.state.selectedauthor': {
    //   deep: true,
    //   handler(data) {
    //     // console.log("11", data);
    //     this.institutionselecttree = data
    //     this.getdisciplinelist()
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.disciplineListrt {
  .authorsearchBox {
    margin: 15px 0;

    .searchBox {
      width: 400px;
      height: 40px;

      /deep/.el-input__inner {
        background: #ffffff;
        border-radius: 4px;
      }
    }

    .searchlistBtn {
      margin-left: 10px;
    }
  }

  .searchNum {
    font-size: 14px;
    font-weight: bold;
    color: @my-color;
  }

  .ListgroupBox {
    display: flex;
    flex-wrap: wrap;
    width: 1138px;
    cursor: pointer;

    .Listgroup {
      margin: 0 19px 20px 0;
      padding: 11px 10px 11px 10px;
      width: 360px;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;

      .itemimgbox {
        text-align: right;

        .itemdiscipline {
          height: 20px;
          background: #ff7100;
          // border-radius: 0px .2083vw 0px .2083vw;
          color: #fff;
          display: inline-block;
          // padding: .2083vw .5208vw;
          border-radius: 4px;
          padding-left: 6px;
          padding-right: 8px;
          position: absolute;
          top: 22px;
          right: 21px;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }

        .imgdis {
          width: 340px;
          height: 169px;
          border-radius: 4px;
          overflow: hidden;
          object-fit: cover;
        }
      }

      .itemtexBox {
        // padding: 1.0417vw;

        .itemtitle {
          margin-top: 15px;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          // height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .word {
          margin-top: 16px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .itemnum {
          border: 1px solid #9c2526;
          border-radius: 4px;
          padding: 3px 6px;
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: @my-color;
          margin: 15px 0;
        }

        .itemtxt {
          margin-top: 8px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          max-width: 305px;
          text-overflow: ellipsis;

          .spantxt {
            padding: 4px 7px 4px 9px;
            background: #f7f7f7;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 400;
            color: #9c2526;
            margin-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .spantxt:first-child {
            margin-left: 0px;
          }
        }
      }
    }

    // .Listgroup:nth-child(3),
    // .Listgroup:nth-child(6),
    // .Listgroup:nth-child(9) {
    //   margin-right: 0px;
    // }
  }
}

.noData {

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 400px;
    // height;
  }

  span {
    color: #333;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Meta from './utils/meta.js';
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'

// 导入全局样式
import './assets/css/public.css'
import './assets/css/media.less'
import './assets/css/global.less'
// 引入字体样式
import '@/assets/iconfont/iconfont.css'
// 引入主题颜色
import '@/assets/theme/index.css'

// 导入防抖js
import preventClick from './directive/preventClick'

import 'swiper/css/swiper.css'

import resetMessage from './assets/js/resetMessage'

// 引入 ECharts
import * as echarts from 'echarts'
// 导入判断token是否失效
// import { tokenMin } from '@/utils/tokenMixin'
// 导入axios

import axios from 'axios'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import htmlToPdf from '@/utils/htmlToPdf'
import 'lib-flexible/flexible.js'
import './utils/resetSize'

// Vue.mixin(tokenMin)

// import htmlToPdf from '@/components/utils/htmlToPdf'
var option = {
  maxSpreadZoom: 1, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
  fullscreenEl: false, // 控制是否显示右上角全屏按钮
  closeEl: false, // 控制是否显示右上角关闭按钮
  tapToClose: true, // 点击滑动区域应关闭图库
  shareEl: false, // 控制是否显示分享按钮
  zoomEl: false, // 控制是否显示放大缩小按钮
  counterEl: false, // 控制是否显示左上角图片数量按钮
  arrowEl: true, // 控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, // 点击应切换控件的可见性
  clickToCloseNonZoomable: true, // 点击图片应关闭图库，仅当图像小于视口的大小时
  modal: true
}
Vue.use(preview, option)
Vue.use(ElementUI)
Vue.use(Meta)
// 这个文件是重写element的样式,必须放在引用element下,不要挪动
import './assets/css/myLoading.css'
Vue.prototype.$message = resetMessage
Vue.config.productionTip = false
Vue.use(preventClick)
Vue.prototype.$echarts = echarts
Vue.use(htmlToPdf)
// Vue.use(pdfannotate)

// // 配置请求根路径
// axios.defaults.baseURL = '/api/' // 本地
axios.defaults.baseURL = 'https://zszwx.cn/zszwx/' // 人大正式服
// axios.defaults.baseURL = 'http://111.198.56.79:28016/literatureServer/'// 211测试打包
// axios.defaults.baseURL = 'https://zwx.lidacul.com/literatureServer/'// 158预发布

// 设置全局URL
//  Vue.prototype.BASE_URL = '/api/'
Vue.prototype.BASE_URL = 'https://zszwx.cn/zszwx/' // 人大正式服
// Vue.prototype.BASE_URL = 'http://111.198.56.79:28016/literatureServer/' // 211测试打包
// Vue.prototype.BASE_URL = 'https://zwx.lidacul.com/literatureServer/' // 211预发布-158

// 设置全局路由URL
//  Vue.prototype.ROUTER_BASE_URL = '/api/'
Vue.prototype.ROUTER_BASE_URL = 'https://zszwx.cn' // 人大正式服
// Vue.prototype.ROUTER_BASE_URL = 'http://111.198.56.79:28016' // 211测试打包
// Vue.prototype.ROUTER_BASE_URL = 'https://zwx.lidacul.com' // 211预发布-158

// 配置一个全局参数,用来修改是否第三方登录
Vue.prototype.THREE_LOGIN_OR = '1' // 如果是1就是第三方,如果是2就是本地
// 维护开始时间
Vue.prototype.START_TIME = new Date('2024-04-24T17:40:00')
// 维护结束时间
Vue.prototype.END_TIME = new Date('2024-04-24T18:00:00')
// 配置请求拦截器
axios.interceptors.request.use(config => {
  // if (!localStorage.getItem('token')) {
  //   store.commit('setUserToken', '')
  //   return config
  // }
  const currentTime = new Date()
  const isMaintenanceTime = currentTime >= Vue.prototype.START_TIME && currentTime <= Vue.prototype.END_TIME
  if (isMaintenanceTime) {
    router.push('./page/main/maintenance')
    return
  }
  config.headers.Authorization = 'Bearer ' + localStorage.getItem('token') || ''

  // 验证token是否过期
  // tokenMin()
  return config
})

// axios.interceptors.response.use(res => {
//   return res
// }, err => {
//   console.log('test', err.response.status)
//   if (err.response) {
//     if (err.response.status === 403) {
//     }
//   }
//   // if (err.response.data.message) {
//   //   Vue.prototype.$message({
//   //     type: 'error',
//   //     message: err.response.data.message
//   //   })
//   //   if (err.response.status === 401) {
//   //     router.push('/login')
//   //   }
//   // }
//   return Promise.reject(err)
// })
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

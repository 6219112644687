<template>
  <div v-loading="isLoad" element-loading-spinner=none element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="journalBox">
      <div class="tabSelect">
        <span class="selectItem active" @click="selectCoregory(0, $event)">全部</span>
        <span class="selectItem" v-for='(item, index) in categoryList' :key='item.key'
          @click="selectCoregory(index + 1, $event)">{{
            item.value }}</span>
      </div>
      <div class="authorsearchBox">
        <div class="searchright">
          <el-input class="searchBox journalSearchBox" @keydown.enter.native="handlejournasearch" clearable
            v-model="journamsearchs" placeholder="输入期刊名称"></el-input>
          <el-button type="primary" class="searchlistBtn" @click="handlejournasearch">结果中搜索</el-button>
        </div>
        <span class="hottip">
          <div class="switch">
            <div id="type0" class="click active" @click="handletype0">按入选量排序</div>
            <div id="type1" class="disclick disactive" @click="handletype1">按音序排序</div>
          </div>
        </span>
      </div>
      <div>
        <span>为您查到</span>
        <span class="searchNum">{{ total }}</span>
        <span>条数据</span>
      </div>
    </div>
    <div class="journalBox journalInfo" style="width: 76.5%;">
      <div class="lefttree">
        <journalTree @nameList='nameListShow(arguments)' @sendIsCheck='sendIsCheck' @sendIsCheckAll='sendIsCheckAll'
          :mainlefttree='mainlefttree' ref='journalTree'></journalTree>
      </div>
      <div class="rightInfo">
        <div class="journalList ml">
          <template v-if='total > 0'>
            <div class="journalItem" @click="handleitem(item.id, item.name)" v-for="(item, index) in journallist"
              :key="index" @mousedown="loopZoom()" @mouseup="clearLoopZoom()">
              <div class="journalItemimg">
                <img :src="item.path" v-if="item.path" alt />
                <div class="empjournal" v-else>
                  <img src="../../assets/images/home/journalimg.png" style="width: 93%;height:41%;margin-top: 55%;"
                    alt />
                </div>
              </div>
              <p class="itemTxt overflow-dot" :title="item.name">{{ item.name }}</p>
            </div>
          </template>
          <div class="empty" v-else>
            <img src="../../assets/images/home/nodatasmall.png" alt="">
            <span>暂无数据</span>
          </div>
          <!-- <div class="journalItem" style="opacity: 0;" v-if="journallist.length != 16" v-for="(item, index) in (16-journallist.length)">{{ index }}</div> -->
        </div>
        <div class="pageBox" style="margin-bottom: 20px;" v-if='total > 0'>
          <div class="listgroupLine"></div>
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="page" :page-sizes="[28, 35, 42, 49]" :page-size="pageRow"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import journalTree from '../../components/journal/journalTree.vue'
export default {
  components: { journalTree },
  data () {
    return {
      journalTree: [],
      checkAll: false,
      mainlefttree: {
        url: '/periodical/disc/count', // 作者树
        title: '学科领域'
      },
      isChecked: false,
      isLoad: false,
      currentPage: 1,
      page: 1, // 页码
      pageRow: 28, // 每页显示的条目数
      total: 0,
      hotwordsearch: '',
      journallist: [],
      journamsearch: '', // 期刊搜索
      journamsearchs: '', // 期刊搜索
      orderType: 0,
      flag: true,
      firstTime: '',
      lastTime: '',
      nameList: [],
      categoryList: [], // 类型字典
      cateGory: [],
      seleteOne: [],
      seleteTwo: [],
      maxSearch: ''
    }
  },
  methods: {
    nameListShow (data) {
      this.jounalTree = data[0]
      this.seleteOne = data[1]
      this.seleteTwo = data[2]
      if (data[1] && data[2]) {
        // 这是把所有一级和二级选出来了
        this.jounalTree = this.jounalTree.map(item => {
          if (this.seleteOne.includes(item.id)) {
            return item.children
          }
        }).filter(Boolean)
        // .map(item => item.nameList).flat()
        this.jounalTree = this.jounalTree[0].filter(item => this.seleteTwo.includes(item.id)).map(item => item.nameList).flat()
        if (JSON.stringify(this.nameList) !== JSON.stringify(this.jounalTree)) {
          this.nameList = this.jounalTree
          this.getjournallist()
        }
      }
    },
    sendIsCheck (data) {
      // console.log('data',)
      this.isChecked = data
      // console.log('data', this.isChecked)
    },
    sendIsCheckAll (data) {
      this.checkAll = data
    },
    // 选择上面
    selectCoregory (index, e) {
      const El = document.querySelectorAll('.selectItem')
      // 循环移除类名
      for (let i = 0; i < El.length; i++) {
        El[i].classList.remove('active')
      }
      e.currentTarget.className = 'selectItem active'
      // console.log(index, e)
      if (index === 0) {
        this.cateGory = []
      } else {
        this.cateGory = [this.categoryList[index - 1].key]
      }
      this.$store.commit('setCagegoryList', this.cateGory)
      // console.log('选中', this.cateGory)
      this.page = 1
      this.getjournallist()
    },
    handletype0 () {
      const element = document.getElementById('type0')
      element.classList.remove('disactive')
      element.classList.add('active')
      const element2 = document.getElementById('type1')
      element2.classList.remove('active')
      element2.classList.add('disactive')
      this.orderType = 0
      this.page = 1
      this.pageRow = 28
      this.getjournallist()
    },
    // 获取类型字典
    async handleCategoryList () {
      const res = await this.$http({
        url: '/periodical/dict/category',
        method: 'GET'
      })
      // console.log(res)
      if (res.data.code !== 200) return this.$message.error(res.data.msg)
      this.categoryList = res.data.data
    },
    handletype1 () {
      const element = document.getElementById('type1')
      element.classList.remove('disactive')
      element.classList.add('active')
      const element2 = document.getElementById('type0')
      element2.classList.remove('active')
      element2.classList.add('disactive')
      this.orderType = 1
      this.page = 1
      this.pageRow = 28
      this.getjournallist()
    },
    // 期刊搜索
    handlejournasearch () {
      this.journamsearch = this.journamsearchs
      // this.journamsearch = this.journamsearch.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.page = 1
      this.$store.commit('setJournalLittleSearch', this.journamsearchs)
      if (document.querySelector('.searchInput')) {
        this.maxSearch = document.querySelector('.searchInput').children[0].value
      }
      if (this.$store.state.hotwordsearch.search !== '' && this.maxSearch === '') {
        this.$store.commit('handlehotwordsearch', '')
        this.$store.commit('handlehotwordsearchTime', Date.now())
      }
      this.getjournallist()
    },
    handleSizeChange (val) {
      this.pageRow = val
      this.page = 1
      this.getjournallist()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getjournallist()
    },
    loopZoom () {
      this.firstTime = new Date().getTime()
      // this.timeOut = setTimeout(() => {
      //   this.flag = false
      // }, 500)
    },
    clearLoopZoom () {
      this.lastTime = new Date().getTime()
      if (this.lastTime - this.firstTime < 200) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    handleitem (id, name) {
      if (this.flag) {
        const routeData = this.$router.resolve({
          path: 'journaldetail?id=' + id + '&name=' + name
          // query: params
        })
        window.open(routeData.href, '_blank')
      }
    },
    // 期刊数据
    // 获取详情接口
    async getjournallist () {
      this.isLoad = true
      try {
        const { data: res } = await this.$http({
          url: '/periodical/search/1',
          method: 'POST',
          data: {
            page: this.page,
            pageSize: this.pageRow,
            searchMap: {
              isChecked: this.isChecked,
              content: this.hotwordsearch,
              resultContent: this.journamsearch,
              orderType: this.orderType,
              nameList: this.nameList,
              categoryList: this.cateGory
            }

          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.journallist = res.data.rows
        this.total = res.data.total
        window.scrollTo(0, 0)
        this.isLoad = false
      } catch (err) {
        this.isLoad = false
      }
    }
  },
  mounted () {
    document.title = '期刊' + this.$route.meta.title
    document.querySelector('.searchBox .searchBtn').click()
    this.handleCategoryList()
    document.onkeydown = e => {
      var theEvent = window.event || e
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode
      if (
        code == 13 &&
        document.activeElement.parentNode.className.indexOf('searchBox') != -1
      ) {
        this.page = 1
        this.getjournallist()
      }
    }
  },
  watch: {
    // 监听热搜词
    '$store.state.hotwordsearch': {
      deep: true,
      // immediate: true,
      handler (data) {
        this.hotwordsearch = data.search
        this.page = 1
        this.pageRow = 28
        const journalSearchEl = document.querySelector('.journalSearchBox')
        // console.log('1111', journalSearchEl)
        this.journamsearch = journalSearchEl.children[0].value
        // console.log(this.journamsearch)
        // this.$store.commit('setJournalLittleSearch', this.journamsearchs)
        this.getjournallist()
      }
    },
    // 监听学科树
    '$store.state.journalSubjectList': {
      deep: true,
      // immediate: true,
      handler (data, oldData) {
        this.isChecked = this.$refs.journalTree.ischecked
        // console.log(data, oldData)
        // console.log('first', this.$refs.journalTree.ischecked)
        if (JSON.stringify(data) !== JSON.stringify(oldData)) {
          // this.hotwordsearch = data.search
          this.page = 1
          this.pageRow = 28
          // this.isChecked = this.$refs.journalTree.ischecked
          // console.log('data', data, oldData)
          this.nameList = data
          this.getjournallist()
        }
      }
    }
    // isChecked: {
    //   handler (data, oldData) {
    //     console.log('isChecked', data)
    //     // if (data !== oldData) {
    //     // this.page = 1
    //     // this.pageRow = 21
    //     this.getjournallist()
    //     // } else if (this.$refs.journalTree.checkAll === true) {
    //     //   this.page = 1
    //     //   this.pageRow = 21
    //     //   this.getjournallist()
    //     // }
    //   }
    // }
    // checkAll: {
    //   handler (data, oldData) {
    //     // console.log('isChecked', data)
    //     // if (data !== oldData) {

    //     this.page = 1
    //     this.pageRow = 21
    //     this.getjournallist()
    //     // } else if (this.$refs.journalTree.checkAll === true) {
    //     //   this.page = 1
    //     //   this.pageRow = 21
    //     //   this.getjournallist()
    //     // }
    //   }
    // }
  }
}
</script>
<style scoped lang="less">
.journalBox {
  width: 75.5%;
  margin: 0 auto;
  // padding-bottom: 20px;

  .authorsearchBox {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchBox {
      width: 400px;
      height: 40px;

      /deep/.el-input__inner {
        background: #ffffff;
        border-radius: 4px;
      }
    }

    .searchlistBtn {
      margin-left: 10px;
    }
  }

  .searchNum {
    font-size: 14px;
    font-weight: bold;
    color: @my-color;
  }

  .journalList {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    // justify-content: space-between;

    // @media screen and (min-width: 1600px) {
    .journalItem {
      // margin: 20px 18px 0 0;
      margin-bottom: 20px;
      margin-right: 17px;
      width: 145px;
      height: 220px;
      box-sizing: border-box;
      // display: inline-block;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      text-align: center;
      // padding: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .journalItemimg {
        padding: 9.6px;
        box-sizing: border-box;
        width: 100%;
        height: 186.4992px;
        overflow: hidden;
        box-sizing: border-box;

        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          // object-fit: contain;
        }

        .empjournal {
          height: 100%;
          background: #fafafc;
          border-radius: 4px;
        }
      }

      .itemTxt {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        padding: 10px 10px 0;
        overflow: hidden;
        width: 100px;
      }
    }

    // }

    // 1300~1600
    // @media screen and (max-width: 1600px) and (min-width: 1300px) {
    //   .journalItem {
    //     margin-bottom: 20px;
    //     margin-right: 17px;
    //     // margin: 20px 18px 0 0;
    //     width: 145px;
    //     height: 220px;
    //     box-sizing: border-box;
    //     // display: inline-block;
    //     border: 1px solid #e0e0e0;
    //     border-radius: 4px;
    //     text-align: center;
    //     // padding: 10px;
    //     box-sizing: border-box;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: flex-start;

    //     .journalItemimg {
    //       padding: 9.6px;
    //       box-sizing: border-box;
    //       width: 100%;
    //       height: 186.4992px;
    //       overflow: hidden;
    //       box-sizing: border-box;

    //       >img {
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         // object-fit: contain;
    //       }

    //       .empjournal {
    //         height: 100%;
    //         background: #fafafc;
    //         border-radius: 4px;
    //       }
    //     }

    //     .itemTxt {
    //       font-size: 14px;
    //       font-weight: 500;
    //       color: #000000;
    //       padding: 10px 10px 0;
    //       overflow: hidden;
    //       width: 100px;
    //     }
    //   }
    // }

    // // 小于1300
    // @media screen and (max-width: 1300px) {
    //   .journalItem {
    //     margin: 20px 38.4px 0 0;
    //     width: 144.8006px;
    //     height: 218.7994px;
    //     box-sizing: border-box;
    //     // display: inline-block;
    //     border: 1px solid #e0e0e0;
    //     border-radius: 4px;
    //     text-align: center;
    //     // padding: 10px;
    //     box-sizing: border-box;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: flex-start;

    //     .journalItemimg {
    //       padding: 9.6px;
    //       box-sizing: border-box;
    //       width: 100%;
    //       height: 186.4992px;
    //       overflow: hidden;
    //       box-sizing: border-box;

    //       >img {
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         // object-fit: contain;
    //       }

    //       .empjournal {
    //         height: 100%;
    //         background: #fafafc;
    //         border-radius: 4px;
    //       }
    //     }

    //     .itemTxt {
    //       font-size: 14px;
    //       font-weight: 500;
    //       color: #000000;
    //       padding: 10px 10px 0;
    //       overflow: hidden;
    //       width: 100px;
    //     }
    //   }
    // }

    .journalItem:nth-child(8),
    .journalItem:nth-child(16),
    .journalItem:nth-child(24),
    .journalItem:nth-child(32),
    .journalItem:nth-child(40),
    .journalItem:nth-child(48),
    .journalItem:nth-child(56),
    .journalItem:nth-child(64),
    .journalItem:nth-child(72) {
      // margin-right: 0px;
    }
  }

  .ml {
    margin-left: 21px;
  }
}

.hottip {
  .switch {
    display: flex;
    width: 230px;
    height: 40px;
    background: #f4f4f5;
    border-radius: 20px;

    .click {
      width: 110px;
      height: 32px;
      border-radius: 16px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      margin-top: 4px;
      line-height: 32px;
      margin-left: 5px;
      text-align: center;
      cursor: pointer;
    }

    .disclick {
      width: 110px;
      height: 32px;
      border-radius: 16px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      margin-top: 4px;
      line-height: 32px;
      margin-left: 5px;
      margin-right: 5px;
      text-align: center;
      cursor: pointer;
    }

    .active {
      background: #9c2526;
      color: #ffffff;
    }

    .disactive {
      background: #f4f4f5;
      color: #000000;
    }
  }

  // display: flex;

  // .tiptext {
  //   font-size: 12px;
  //   font-family: Source Han Sans CN;
  //   font-weight: 400;
  //   color: #FE0000;
  // }

  // .icon {
  //   width: 16px;
  //   height: 16px;
  //   background: url('../../assets/images/home/tip.png');
  //   background-size: cover;
  //   background-position: center center;
  //   margin-right: 9px;
  //   margin-top: 1px;
  // }
}

.empty {
  margin: 10px 350px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lefttree {
  width: 300px;
  // height: 600px;
}

.journalInfo {
  display: flex;
  margin-top: 20px;
}

.tabSelect {
  height: 32px;

  // background-color: green;
  .selectItem {
    box-sizing: border-box;
    display: inline-block;
    background-color: transparent;
    line-height: 32px;
    font-size: 14px;
    height: 32px;
    text-align: center;
    border-radius: 16px;
    color: #000;
    padding: 0 17px;
    margin-right: 25px;
    cursor: pointer;
  }

  .selectItem.active {
    box-sizing: border-box;
    display: inline-block;
    background-color: #9c2526;
    line-height: 32px;
    font-size: 14px;
    height: 32px;
    text-align: center;
    border-radius: 16px;
    color: #fff;
    padding: 0 17px;
    margin-right: 25px;
    cursor: pointer;
  }
}

.el-pagination {

  // margin-left: 300px;
}

.pageBox {
  width: 1100px;

  .el-pagination {
    margin: 0 auto;
  }
}
</style>

<template>
  <!-- 红色登录 -->
  <div class="headloginBox">
    <!-- 头部登录 -->
    <div class="lfLogin" @click="handleLogoClick">
      <img src="../../assets/images/head/logo.png" alt />
      <div class="indexHome">
        <div class="left"></div>
        <div class="right">
          <p>首页</p>
        </div>
      </div>
    </div>
    <div class="rttextBox">
      <div class="headtxt">
        <img src="../../assets/images/head/slogan.png" alt />
      </div>
      <div class="loginregisterBox" v-if="!token" @click="loginhandle">
        <span>登录</span>
      </div>
      <div class="loginsuccessBox" v-if="token">
        <el-badge class="messageNum" :value="messageNum">
          <img
            class="Personaimg"
            @click="goUserNotic"
            :src="require('../../assets/images/head/message.png')"
            alt
          />
        </el-badge>

        <span class="headname">{{ userName }}</span>

        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <img class="logins" v-if="imageUrl" :src="imageUrl" alt />
            <img
              class="logins"
              v-else
              :src="require('../../assets/images/head/logins.png')"
              alt
            />
            <!-- <img :src="require('../../assets/images/head/headPersonaimg.png')" alt /> -->
            <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
            <span class="arrow"></span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">个人信息</el-dropdown-item>
            <el-dropdown-item command="1">我的收藏</el-dropdown-item>
            <!-- <el-dropdown-item command="2">我的笔记</el-dropdown-item> -->
            <el-dropdown-item command="3">我的订阅</el-dropdown-item>
            <el-dropdown-item command="4">我的认领</el-dropdown-item>
            <el-dropdown-item command="5">我的评论</el-dropdown-item>
            <el-dropdown-item command="6">通知消息</el-dropdown-item>
            <el-dropdown-item command="7">修改密码</el-dropdown-item>
            <el-dropdown-item command="8">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- <div class="opinion" @click="handleopinion">
      <span class="icon"></span>
      <div class="opintip">意见反馈</div>
    </div> -->
    <login
      ref="login"
      class="homeLoginBigBox"
      @registeropen="registeropen"
    ></login>
    <register
      ref="register"
      @loginopen="loginopen"
      @emitSuccessRegister="$refs.login.modelopen = true"
    ></register>
    <!-- <opinions ref="opinions" @opinionsopen="opinionsopen"></opinions> -->
  </div>
</template>

<script>
import login from "../../page/login";
import register from "../../page/register";
// import opinions from "../head/opinions.vue";
import { threeHandleLogin } from "../../utils/threeLogin";
export default {
  data() {
    return {
      setSecond: null,
      subjectName: "",
      phone: "",
      userName: "", // 用户名
      token: "", // 登录状态
      imageUrl: "",
      messageNum: "", // 通知数量
      opinionsopen: false,
      isOpinion: true,
      mainbrowse: "", // 浏览器地址
      referenceNumber: "",
      id: "",
      startYearEl: "",
      endYearEl: "",
      mainListBoxEl: "",
      maxSearch: "",
    };
  },
  components: {
    login,
    register,
    // opinions
  },
  mounted() {
    this.userName = this.$store.state.userName;
    this.phone = localStorage.getItem("phone");
    // if (localStorage.getItem('token')) {
    //   this.getpersonal()
    //   // console.log("rrroo")
    //   this.getUserMessage()
    // }
    this.subjectName = this.$route.query.subjectName;
    this.$store.commit("setSubjectName", this.subjectName);
    if (localStorage.getItem("token")) {
      // this.getpersonal()
      this.getUserMessage();
    } else {
      this.handleSubjectName();
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path && this.$store.state.token && to.path === "/") {
        this.getpersonal();
      }
    },
    "$store.state.token": {
      deep: true,
      immediate: true,
      handler(newName, oldName) {
        if (newName !== oldName) {
          this.token = newName;
          this.myChannel();
          if (this.token) {
            localStorage.setItem("token", newName);
            this.getpersonal();
          } else {
            this.handleSubjectName();
          }
        }
      },
    },
    "$store.state.imageUrl": {
      immediate: true,
      handler(newVal) {
        this.imageUrl = newVal;
      },
    },
    "$store.state.userName": {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.userName = newVal;
      },
    },
  },
  methods: {
    async handleSubjectName() {
      if (this.$route.query.subjectName) {
        this.subjectName = this.$route.query.subjectName;
        const res = await this.$http({
          url: "/literatureInfoList/getSecondDiscByName",
          method: "GET",
          params: {
            subjectName: this.subjectName,
          },
        });
        if (res.data.data) {
          this.secondLevelList = res.data.data.map((item) => {
            return item.code;
          });
        }
        //  else {
        //   this.secondLevelList = []
        // }
        this.$store.commit("secondLevelList", this.secondLevelList);
      }
      // this.loading = false
    },
    myChannel() {
      // 创建广播频道
      const bc = new BroadcastChannel("myChannel");
      // 发送消息
      // console.log('location.origin', location.origin)
      bc.postMessage(localStorage.getItem("token"));
      const that = this;
      bc.onmessage = function (e) {
        if (e.origin === location.origin) {
          if (!e.data) {
            that.token = false;
            that.$store.commit("setUserToken", null);
            that.$store.commit("userName", null);
            that.$store.commit("setImage", "");
            that.$store.commit("secondLevelList", []);
            that.$store.commit("firstLevelList", []);
          } else {
            // console.log("进2", e.data);
            that.$store.commit("setUserToken", localStorage.getItem("token"));
          }
        }
      };
    },
    open() {
      document.addEventListener("click", this.close, false);
    },
    close(e) {
      const boxEl = document.querySelector(".opinionsClose");
      if (boxEl) {
        if (!boxEl.contains(e.target) && e.target.className !== "opinion") {
          this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen;
          if (this.$refs.opinions.opinionsopen === false) {
            this.removeListener();
          }
        }
      }
    },
    removeListener() {
      document.removeEventListener("click", this.close, false);
    },
    removeLogin() {
      localStorage.removeItem("expiration");
      localStorage.removeItem("userImage");
      localStorage.removeItem("userName");
      localStorage.removeItem("phone");
      localStorage.removeItem("token");
      this.$store.commit("userName", "");
      this.$store.commit("setUserToken", "");
      if (this.subjectName !== undefined) {
        this.handleSubjectName();
      } else {
        this.$store.commit("secondLevelList", []);
        this.$store.commit("firstLevelList", []);
      }

      // this.$store.commit('secondLevelList', [])
      // this.$store.commit('firstLevelList', [])
      if (this.$route.matched[0].path == "/user") {
        this.$router.push("/main");
      }
    },
    async getpersonal() {
      // 查询个人信息
      const { data: res } = await this.$http({
        url: "/userinfo",
        method: "GET",
      });
      if (res.code === 115001 && localStorage.getItem("token")) {
        // 删除token
        this.removeLogin();
        return this.$message.error("登录已失效,请重新登录");
      } else {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          if (localStorage.getItem("thirdcode")) {
            localStorage.removeItem("thirdcode");
            location.href = this.BASE_URL + "authentication/logout";
          } else {
            this.$router.push("/main").then(() => {
              location.reload(true);
            });
          }
          this.removeLogin();
        }
      }
      if (res.code === 200) {
        if (res.data.url == null) {
          res.data.url = "";
        }
        if (!res.data.phone) {
          return this.$message.error("您的手机号已更改,请重新登录");
        }
        this.$store.commit("userName", res.data.nickName);
        this.$store.commit("setImage", res.data.url + res.data.photo);
        this.$store.commit("setSuperPhone", res.data.phone);
        // this.$store.commit('imageUrl', res.data.url + res.data.photo)

        // 登录成功以后
        const firstdisciplineList = res.data.firstLevelDiscipline;

        // 二级学科
        const seconddisciplineList = res.data.secondLevelDiscipline;
        const firstDisc = [];
        // if (this.firstdisciplineList) {
        firstdisciplineList.forEach((item) => {
          if (item.checked === "true") {
            firstDisc.push({
              code: item.code,
              name: item.name,
            });
          }
        });
        let second = [];
        second = firstDisc;
        if (seconddisciplineList) {
          seconddisciplineList.forEach((item) => {
            if (item.childList) {
              item.childList.forEach((el) => {
                if (el.checked === "true") {
                  second.forEach((ement) => {
                    if (ement.code === el.code.substring(0, 4)) {
                      if (ement.childList) {
                        ement.childList.push({
                          code: el.code,
                          name: el.name,
                        });
                      } else {
                        ement.childList = [];
                        ement.childList.push({
                          code: el.code,
                          name: el.name,
                        });
                      }
                    }
                  });
                }
              });
            }
          });
        }
        const firsttree = [];
        const secondtree = [];
        if (second.length) {
          second.forEach((item) => {
            if (item.childList) {
              firsttree.push(item.code);
              item.childList.forEach((val) => {
                secondtree.push(val.code);
              });
            }
          });
        }
        this.$store.commit("secondLevelList", secondtree);
        this.$store.commit("firstLevelList", firsttree);
        // 用setSecond来判断当前用户是否登录,如果登录了,他的setSecond就是空数组或者不是空数组,未登录状态就是null,用这一个东西来控制首页左侧学科树,中间mainlist和右侧数量的接口请求次数,当判断到userinfo请求后,下面三个就会等待userinfo请求返回到数据,下面三个会存储后再请求,如果没有登录,setSecond就是null,下面三个就会直接请求
        this.setSecond = secondtree;
        this.$store.commit("setSetSecond", this.setSecond);
        this.token = localStorage.getItem("token");
      }
    },
    // 获得通知消息
    async getUserMessage() {
      const res = await this.$http({
        url: "/userinfo/unReadMesCount",
        method: "POST",
      });
      if (res.data.code === 200) {
        if (res.data.data > 99) {
          this.messageNum = "99+";
        } else if (res.data.data === 0) {
          this.messageNum = "";
          // this.messageNum = res.data.data
        } else {
          this.messageNum = res.data.data;
        }
      }
    },
    goUserNotic() {
      this.$router.push("/user/notice");
    },
    // 点击退出清空token
    handleCommand(command) {
      // 在这里可以执行自己的逻辑操作
      if (this.$store.state.threeReset && command === "7") {
        location.replace(this.BASE_URL + "authentication/password/reset");
      }
      switch (command) {
        case "0":
          this.$router.push("/user/personal");
          break;
        case "1":
          this.$router.push("/user/collect");
          break;
        case "2":
          this.$router.push("/user/note");
          break;
        case "3":
          this.$router.push("/user/subscribe");
          break;
        case "4":
          this.$router.push("/user/myClaim");
          break;
        case "5":
          this.$router.push("/user/myComment");
          break;
        case "6":
          this.$router.push("/user/notice");
          break;
        case "7":
          this.$router.push("/user/updatePassword");
          break;
        case "8":
          this.logout();

          break;
      }
    },
    loginhandle() {
      const maintenanceStartTime = this.START_TIME; // 维护开始时间
      const maintenanceEndTime = this.END_TIME; // 维护结束时间
      const currentTime = new Date();
      if (
        currentTime >= maintenanceStartTime &&
        currentTime <= maintenanceEndTime
      ) {
        location.reload();
      }
      threeHandleLogin(this.THREE_LOGIN_OR);
      // this.$refs.login.modelopen = true
    },
    // 退出登录
    async logout() {
      this.myChannel();
      localStorage.removeItem("token");
      localStorage.removeItem("userImage");
      localStorage.removeItem("userName");
      localStorage.removeItem("phone");
      this.$store.commit("setImage", "");
      this.$store.commit("userName", "");
      this.$store.commit("setUserToken", "");
      this.$store.commit("secondLevelList", []);
      this.$store.commit("firstLevelList", []);
      if (localStorage.getItem("thirdcode")) {
        localStorage.removeItem("thirdcode");
        location.href = this.BASE_URL + "authentication/logout";
      } else {
        this.$router.push("/main").then(() => {
          location.reload(true);
        });
      }
    },
    registeropen(value) {
      this.$refs.register.registershow = value;
    },
    loginopen(value) {
      this.$refs.login.modelopen = value;
    },
    handleopinion() {
      if (this.token) {
        // 已登录
        this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen;
      } else {
        // 未登录
        this.loginhandle();
      }
    },

    // 回到首页
    handleLogoClick() {
      document.querySelector(".home").style.backgroundColor = "#fff";
      document.querySelector(".middleContainer").style.backgroundColor = "#fff";
      // 获取首页三个小搜索内容
      if (document.querySelector(".startYearSearch")) {
        this.startYearEl =
          document.querySelector(".startYearSearch").children[0].value;
        this.endYearEl =
          document.querySelector(".endYearSearch").children[0].value;
        this.mainListBoxEl =
          document.querySelector(".mainLisSearchBox").children[0].value;
      }
      // 获取大搜索内容
      if (document.querySelector(".searchInput")) {
        this.maxSearch =
          document.querySelector(".searchInput").children[0].value;
      }
      // 如果当前页在首页点击回首页

      // 如果都为空就赋空值,否则不变

      this.$store.commit("yearsearch", {
        pubYear: "",
        resultContent: "",
        startTime: "",
        endTime: "",
      });

      this.$store.commit("handlehotwordsearch", "");
      this.$store.commit("handlehotwordsearchTime", Date.now());

      // this.$store.commit('yearsearch', {
      //   pubYear: '',
      //   resultContent: '',
      //   startTime: '',
      //   endTime: ''
      // })
      setTimeout(() => {
        if (this.$route.path === "/") {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // 平滑滚动效果（可选）
          });
        } else {
          this.$router.push({ name: "mainIndex" });
        }
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.headloginBox {
  height: 68px;
  width: 100%;
  background-color: #9c2526;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 7.2%;
  align-items: center;

  .lfLogin {
    cursor: pointer;
    display: inline;
    height: 80px;
    position: relative;

    > img {
      // width: 158px;
      height: 100%;
    }

    .indexHome {
      position: absolute;
      top: 0;
      // top: 0;
      right: 0;
      transform: translateX(100%);
      display: flex;
      align-items: center;

      .left {
        box-sizing: border-box;
        width: 0px;
        height: 0px;
        // border: ;
        border: 5px solid transparent;
        border-right: 5px solid #ebd3d4;
      }

      .right {
        width: 40px;
        height: 24px;
        background-color: #ebd3d4;
        border-radius: 2px;
        margin-top: 8px;

        p {
          font-size: 14px;
          color: #9c2526;
          text-align: center;
          line-height: 24px;
        }
      }
    }
  }

  .rttextBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .headtxt {
      color: #fff;
      width: 469px;
      height: 28px;
      margin-right: 39px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .loginregisterBox {
      cursor: pointer;
      margin-left: 40px;
      width: 80px;
      height: 32px;
      border: 1px solid #ffffff;
      border-radius: 16px;
      color: #fff;
      line-height: 32px;
      text-align: center;

      span {
        font-size: 16px;
      }
    }

    .loginsuccessBox {
      display: flex;
      align-items: center;
      position: relative;
      transition: all 3s;

      > img {
        vertical-align: middle;
      }

      /deep/.el-badge__content.is-fixed {
        position: absolute;
        transform: translateY(-50%) translateX(30%) !important;
      }

      .headname {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        // vertical-align: super;
        line-height: 68px;
      }

      .logins {
        width: 47px;
        height: 47px;
        border-radius: 50%;
        padding: 0 20px;
        object-fit: cover;
        // transition: all .5s ease;
      }

      .logins:hover + .arrow {
        transition: transform 0.3s linear;
        transform: rotate(180deg);
      }

      .arrow {
        display: inline-block;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top: 7px solid #fff;
        position: absolute;
        top: 50%;
        // transform: translateY(-75%);
        left: 85%;
        transform-origin: 50% 25%;
      }

      .Personaimg {
        cursor: pointer;
        width: 20px;
        height: 24px;
        margin-right: 35px;
      }
    }
  }

  .comment {
    right: 1px;
    bottom: 141px;
    position: fixed;
    width: 60px;
    background: #861d20;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    cursor: pointer;

    .icon1 {
      display: inline-block;
      width: 21px;
      height: 20px;
      background: url("../../assets/images/head/comment.png") no-repeat;
      background-size: cover;
    }

    .opintip {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      // margin-top: 8px;
    }
  }

  .comment:hover {
    right: 1px;
    bottom: 141px;
    position: fixed;
    width: 60px;
    background: #9c2526;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    cursor: pointer;

    .icon1 {
      display: inline-block;
      width: 21px;
      height: 20px;
      background: url("../../assets/images/head/comment_red.png") no-repeat;
      background-size: cover;
    }

    .opintip {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      // margin-top: 8px;
    }
  }

  .opinion {
    right: 1px;
    bottom: 71px;
    position: fixed;
    width: 60px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    cursor: pointer;

    .icon {
      display: inline-block;
      width: 21px;
      height: 20px;
      background: url("../../assets/images/head/space.png") no-repeat;
      background-size: cover;
    }

    .opintip {
      font-size: 12px;
      font-weight: 500;
      color: #666666;
      text-align: center;
      // margin-top: 8px;
    }
  }

  .opinion:hover {
    right: 1px;
    bottom: 71px;
    position: fixed;
    width: 60px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    cursor: pointer;

    .icon {
      display: inline-block;
      width: 21px;
      height: 20px;
      background: url("../../assets/images/head/space_red.png") no-repeat;
      background-size: cover;
    }

    .opintip {
      font-size: 12px;
      font-weight: 500;
      color: #9c2526;
      text-align: center;
      // margin-top: 8px;
    }
  }
}
</style>

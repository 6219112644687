import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../page/home'
import mainhome from '../page/mainhome'
// 登录
import login from '../page/login.vue'

// 首页
// import index from '../page/index.vue'

// 主文献
import main from '../page/main/index.vue'
// 主文献详情
import mainDetails from '../page/main/mainDetails.vue'
// 文献详情的在线阅读
import reading from '../page/main/reading.vue'
import looking from '../page/main/looking.vue'
// 学科专题
import disciplineList from '../page/main/disciplineList.vue'
// 学科专题详情
import disciplinedetail from '../page/main/disciplinedetail.vue'
// 十大学术热点
import tensciencelist from '../page/main/tensciencelist.vue'
// 十大学术热点详情
import tenscienceInfo from '../page/main/tenscienceInfo.vue'
// 十大热词
import subjectHot from '../page/main/subjectHot.vue'
// 遴选页面
import selectlx from '../page/main/selectlx.vue'
// 平台通知详情
import platformNotice from '@/page/main/platformNotice'
// 作者
import author from '../page/author/index.vue'
// 作者详情
import authordetail from '../page/author/authorDetails.vue'
// 机构
import institution from '../page/institution/index.vue'
import institutiondetail from '../page/institution/institutiondetail.vue'
// 期刊
import journal from '../page/journal/index.vue'
// 期刊详情
import journaldetail from '../page/journal/journaldetail.vue'
// 专家团队
import expertteam from '../page/expertteam/index.vue'
// 原创学术概念
import originalList from '../page/main/originalList.vue'
// 原创学术概念详情
import originalDetail from '../page/main/originalDetail.vue'

// 用户
// 个人信息
import user from '../page/user/index.vue'
import personal from '../page/user/personal.vue'
// 我的收藏
import collect from '../page/user/collect.vue'
// 我的笔记
import note from '../page/user/note.vue'
// 我的订阅
import subscribe from '../page/user/subscribe.vue'
// 通知消息
import notice from '../page/user/notice.vue'
// 用户中心修改密码
import updatePassword from '@/page/user/updatePassword'
// 用户中心我的评论
// import myComment from '../page/user/myComment'
// // 用户中心我的认领
import myClaim from '@/page/user/myClaim'
// 系统升级页面
import maintenance from '@/page/main/maintenance'
// 注册协议
import agree from '../page/main/agree.vue'
// 隐私协议
import personalagree from '../page/main/personalagree.vue'
// 平台简介
import introduction from '../page/main/introduction.vue'
// 版权声明
import copyright from '../page/main/copyright.vue'
// 平台问题解答
import answer from '../page/main/answer.vue'
// 关键词页面
import keyWords from '@/page/main/keyWord'
// 安心签登录页
import signaturesLogin from '@/page/digitalSignatures/signaturesLogin'
// 安心签文章列表
import signaturesLiteratureList from '@/page/digitalSignatures/signaturesLiteratureList'
// 安心签文献详情
import signaturesDetail from '@/page/digitalSignatures/signaturesDetail'
// 安心签认领成功
import signaturesSuccess from '@/page/digitalSignatures/signaturesSuccess'
// 安心前面反馈
import signaturesFeedBack from '@/page/digitalSignatures/signaturesFeedBack'
// 用户协议
import signaturesAgree from '@/page/digitalSignatures/signaturesAgree'
// 安心签名隐私
import signaturesPrivacy from '@/page/digitalSignatures/signaturesPrivacy'
// 安心签合同
import signaturesContract from '@/page/digitalSignatures/signaturesContract'
// 安心签告知协议
import signaturesNotification from '@/page/digitalSignatures/signaturesNotification'
// 安心签纯手机号登录
import signaturesPhone from '@/page/digitalSignatures/signaturesPhone'
// 文献列表查看
import signaturesLookList from '@/page/digitalSignatures/signaturesLookList'
// 安心签邀请页
import signaturesinvite from '@/page/digitalSignatures/signaturesinvite'
// 原创学术理论征集
import collectIndex from '@/page/collect/index'

// import { ElStep } from 'element-ui/types/step'
Vue.use(VueRouter)

const routes = [
  // { path: '*', redirect: { name: 'mainIndex' } }, // 访问意料之外的路由时重定向到/
  {
    path: '/',
    redirect: { name: 'mainIndex' },
    name: 'main',
    component: main,
    meta: {
      keepAlive: false,

    }
  },
  // {
  //   path: '/index',
  //   component: index,
  //   meta: {
  //     keepAlive: false
  //   }
  // },
  {
    path: '/login',
    component: login,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/',
    component: home,
    redirect: '/',
    children: [
      {
        path: '/',
        component: mainhome,
        children: [
          {
            path: '/',
            component: main,
            name: 'mainIndex',
            isIndex: true,
            meta: {
              title: '主文献-传播中国学术精品  服务中国知识创新',
              keepAlive: false,
              isIndex: true,
            }
          },
          {
            path: '/author',
            component: author,
            meta: {
              title: '-主文献',
              keepAlive: false
            }
          },
          {
            path: '/institution',
            component: institution,
            meta: {
              title: '-主文献',
              keepAlive: false
            }
          },
          {
            path: '/journal',
            component: journal,
            meta: {
              title: '-主文献',
              keepAlive: false
            }
          },
          {
            path: '/expertteam',
            component: expertteam,
            meta: {
              title: '-主文献',
              keepAlive: false
            }
          }
        ]
      },
      {
        path: '/authordetail', // 作者详情
        component: authordetail,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/mainDetails', // 主文献详情
        component: mainDetails,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/institutiondetail', // 机构详情
        component: institutiondetail,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/disciplineList', // 学科专题
        component: disciplineList,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/disciplinedetail', // 学科专题详情
        component: disciplinedetail,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/originalList', // 原创学术概念
        component: originalList,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/originalDetail', // 原创学术概念详情
        component: originalDetail,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/reading',
        name: 'reading',
        component: reading,
        meta: {
          title: '-主文献',
          keepAlive: false
        } // 在线阅读
      },
      {
        path: '/looking',
        name: 'looking',
        component: looking,
        meta: {
          title: '-主文献',
          keepAlive: false
        } // 在线预览
      },
      {
        path: '/selectlx',
        component: selectlx,
        meta: {
          title: '-主文献',
          keepAlive: false
        } // 遴选指标
      },
      {
        path: '/tensciencelist',
        component: tensciencelist,
        meta: {
          title: '-主文献',
          keepAlive: false
        } // 十大学术
      },
      {
        path: '/tenscienceInfo',
        component: tenscienceInfo,
        meta: {
          title: '-主文献',
          keepAlive: false
        } // 十大学术详情
      },
      {
        path: '/subjectHot',
        component: subjectHot,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/journaldetail',
        component: journaldetail,
        meta: {
          title: '-主文献',
          keepAlive: false
        } // 期刊详情
      },
      {
        path: '/keyWord',
        component: keyWords,
        name: 'keyword',
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/platformNotice',
        component: platformNotice,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
      },
      {
        path: '/introduction',
        component: introduction,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
        // 平台简介
      },
      {
        path: '/copyright',
        component: copyright,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
        // 版权声明
      },
      {
        path: '/answer',
        component: answer,
        meta: {
          title: '-主文献',
          keepAlive: false
        }
        // 有问题解答
      },
      {
        path: '/user',
        component: user,
        children: [
          {
            path: '/user/personal',
            name: 'personal',
            component: personal,
            meta: {
              title: '个人信息',
              keepAlive: false
            }
          },
          {
            path: '/user/collect',
            name: 'collect',
            component: collect,
            meta: {
              title: '我的收藏',
              keepAlive: false
            }
          },
          // {
          //   path: '/user/note',
          //   name: 'note',
          //   component: note,
          //   meta: {
          //     title: '我的笔记',
          //     keepAlive: false
          //   }
          // },
          {
            path: '/user/subscribe',
            name: 'subscribe',
            component: subscribe,
            meta: {
              title: '我的订阅',
              keepAlive: false
            }
          },
          {
            path: '/user/myComment',
            name: 'myComment',
            component: () => import('../page/user/myComment.vue'),
            meta: {
              title: '我的评论',
              keepAlive: false
            }
          },
          {
            path: '/user/myClaim',
            name: 'myClaim',
            component: myClaim,
            meta: {
              title: '我的认领',
              keepAlive: false
            }
          },
          {
            path: '/user/notice',
            name: 'notice',
            component: notice,
            meta: {
              title: '通知消息',
              keepAlive: false
            }
          },
          {
            path: '/user/updatePassword',
            name: 'updatePassword',
            component: updatePassword,
            meta: {
              title: '修改密码',
              keepAlive: false
            }
          }
        ]
      }
    ]
  },

  {
    path: '/agree',
    component: agree,
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/maintenance',
    component: maintenance,
    name: 'maintenance'
  },
  {
    path: '/personalagree',
    component: personalagree,
    meta: {
      title: '-主文献',
      keepAlive: false
    }
    // 隐私协议
  },

  {
    path: '/signaturesLogin',
    component: signaturesLogin,
    name: 'signaturesLogin',
    meta: {
      title: '-主文献',
      keepAlive: true
    }
  },
  {
    path: '/signaturesLiteratureList',
    component: signaturesLiteratureList,
    name: 'signaturesLiteratureList',
    meta: {
      title: '-主文献',
      keepAlive: true
    }
  },
  {
    path: '/signaturesDetail',
    component: signaturesDetail,
    name: 'signaturesDetail',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/signaturesSuccess',
    component: signaturesSuccess,
    name: 'signaturesSuccess',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/signaturesFeedBack',
    component: signaturesFeedBack,
    name: 'signaturesFeedBack',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/signaturesAgree',
    component: signaturesAgree,
    name: 'signaturesAgree',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/signaturesPrivacy',
    component: signaturesPrivacy,
    name: 'signaturesPrivacy',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/signaturesContract',
    component: signaturesContract,
    name: 'signaturesContract',
    meta: {
      title: '-主文献',
      keepAlive: true
    }
  },
  {
    path: '/signaturesNotification',
    component: signaturesNotification,
    name: 'signaturesNotification',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/signaturesPhone',
    component: signaturesPhone,
    name: 'signaturesPhone',
    meta: {
      title: '-主文献',
      keepAlive: true
    }
  },
  {
    path: '/signaturesLookList',
    component: signaturesLookList,
    name: 'signaturesLookList',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/signaturesinvite',
    component: signaturesinvite,
    name: 'signaturesinvite',
    meta: {
      title: '-主文献',
      keepAlive: false
    }
  },
  {
    path: '/collect/index',
    component: collectIndex, 
    name: 'collectIndex',
  }

  // {signaturesPrivacy
  //   path: '/comment',signaturesinvite
  //   // name: 'user',
  //   component: () => import('@/page/user/comment.vue')
  // }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }) // 滚动到顶端
})
router.beforeEach((to, from, next) => {
  console.log('跳转的页面', to)
  const maintenanceStartTime = Vue.prototype.START_TIME // 维护开始时间
  const maintenanceEndTime = Vue.prototype.END_TIME // 维护结束时间
  const currentTime = new Date()
  const isMaintenanceTime =
    currentTime >= maintenanceStartTime && currentTime <= maintenanceEndTime
  const token = localStorage.getItem('token')
  // 如果即将跳转的路由不存在于路由表则检查
  if (to.matched.length === 0) {
    let index = to.path.indexOf("/sg");// 查询链接是否存在固定格式，存在的话跳转到认领，并截取参数
    // 如果不是认领则跳转到首页或者在维护时间内跳转到维护页
    if (index == -1) {
      if (isMaintenanceTime) {
        next({ name: 'maintenance' })
      } else {
        next({ name: 'main' });
      }
    } else {
      // 是认领则去邀请页面
      let path = to.path.substr(index + 1); // +1 目的是去除 /
      console.log('字段匹配', index, ' ', path)
      next({ path: `/signaturesinvite?${path}` });
    }
    return
  }

  if (
    !token &&
    !isMaintenanceTime &&
    (to.name === 'reading' ||
      to.name === 'personal' ||
      to.name === 'collect' ||
      to.name === 'note' ||
      to.name === 'subscribe' ||
      to.name === 'myComment' ||
      to.name === 'myClaim' ||
      to.name === 'notice' ||
      to.name === 'updatePassword')
  ) {
    next({ name: 'main' })
  } else if (isMaintenanceTime) {
    if (to.name === 'maintenance') {
      next()
    } else {
      next({ name: 'maintenance' })
    }
  }
  if (to.name === 'maintenance' && !isMaintenanceTime) {
    next({ name: 'main' })
  } else {
    next()
    // if (to.name !== 'main') location.href = '/main'
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
